<template>
  <div>
	  <Menu />
	  <section id="banner" class="my-5">
      <div class="container">
        <div class="row justify-content-center g-0">
          <div class="col-12">
            <div class="banner-section">
              <img src="images/banners/contact-us.png" class="w-100" />
              <!-- <div class="social-links switch-bg-dblue">
                <div class="row">
                  <div class="col-3"><a href="https://www.facebook.com/ChasEverittProperty/" target="_blank"><img src="/images/icons/facebook.png" class="mw-100" width="20px" /></a></div>
                  <div class="col-3"><a href="https://www.linkedin.com/company/chas-everitt/?trk=top_nav_home" target="_blank"><img src="/images/icons/linkedin.png" class="mw-100" width="20px" /></a></div>
                  <div class="col-3"><a href="https://www.instagram.com/chaseveritt/" target="_blank"><img src="/images/icons/instagram.png" class="mw-100" width="20px" /></a></div>
                  <div class="col-3"><a href="https://www.youtube.com/@ChasEverittSA" target="_blank"><img src="/images/icons/youtube.png" class="mw-100" width="20px" /></a></div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="" class="mt-5 ">
      <div class="container p-0 p-md-5">
        <div class="row justify-content-center g-0 switch-bg-lgrey bord-radius">
          <div class="col-12 px-sm-5 pt-3">
            <div class="banner-section lifestyle-block p-3 h-100">
              <h3 class="mb-4">GET IN TOUCH!</h3>
              <form id="buyForm" class="formStyle" @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <input type="text" class="form-control" v-model="data.name" placeholder="NAME" id="name">
                      <span class="error-message">{{ data.errors.name }}</span>
                    </div>
                    <div class="mb-3">
                      <input type="tel" class="form-control" v-model="data.phone" placeholder="PHONE" id="phone">
                      <span class="error-message">{{ data.errors.phone }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <input type="email" class="form-control" v-model="data.email" placeholder="EMAIL" id="email">
                      <span class="error-message">{{ data.errors.email }}</span>
                    </div>
                    <div class="mb-3">
                      <select class="form-control" id="enquiry" name="enquiry" required v-model="data.enquiry">
                        <option disabled selected value="">ENQUIRY TYPE</option>
                        <option value="Looking to Buy">LOOKING TO BUY</option>
                        <option value="Looking to Sell">LOOKING TO SELL</option>
                        <option value="Looking to Sell">LOOKING TO RENT</option>
                        <option value="Looking to Sell">COMMERCIAL PROPERTY</option>
                      </select>
                      <span class="error-enquiry">{{ data.errors.enquiry }}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <textarea class="form-control" v-model="data.message" placeholder="WHAT ARE YOU LOOKING FOR?" id="message"></textarea>
                      <span class="error-message">{{ data.errors.message }}</span>
                    </div>
                  </div>
                </div>
                  
                <div class="col-md-12">
                  <div class="col-md-12">
                    <div class="form-check mb-2">
                      <input type="checkbox" class="form-check-input" v-model="data.acceptTerms" id="acceptTerms">
                      <label class="form-check-label" for="acceptTerms">I have read and accept the <a target="_blank" href="https://www.chaseveritt.co.za/disclaimer-policy-website/" class="switch-blue">terms and conditions</a></label>
                    </div>
                    <div class="form-check mb-2">
                      <input type="checkbox" class="form-check-input" v-model="data.receiveMarketing" id="receiveMarketing" value="Yes">
                      <label class="form-check-label" for="receiveMarketing">I want to receive marketing material</label>
                    </div>
                  </div>
                 
                  <div class="social-links switch-bg-dblue p-0 form-btn">
                    <button type="submit" class="btn switch-white" :disabled="!data.acceptTerms">SEND MESSAGE</button>
                  </div>
                  <div class="success-message pb-2" v-if="successMessage">{{ successMessage }}</div>
                  <div class="error-message pb-2" v-if="errorMessage">{{ errorMessage }}</div>
                </div>  

                  <!-- <div class="social-links switch-bg-dblue p-0 form-btn">
                    <button type="submit" class="btn mb-0 cta-link">SEND MESSAGE</button>
                  </div>
                  <div class="success-message" v-if="successMessage">{{ successMessage }}</div>
                  <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div> -->
                </form>
            </div>
          </div>
          <!-- <div class="col-lg-6 px-sm-5 pt-3 mt-sm-0 mt-4">
            <div class="banner-section lifestyle-block p-3 h-100">
              <h3 class="mb-4">Looking to sell?</h3>
              <form id="sellForm" class="formStyle" @submit.prevent="submitForm('sellForm')">
                <div class="mb-3">
                  <input type="text" class="form-control" v-model="sellFormData.name" placeholder="NAME" id="name">
                  <span class="error-message">{{ sellFormData.errors.name }}</span>
                </div>
                <div class="mb-3">
                  <input type="email" class="form-control" v-model="sellFormData.email" placeholder="EMAIL" id="email">
                  <span class="error-message">{{ sellFormData.errors.email }}</span>
                </div>
                <div class="mb-3">
                  <input type="tel" class="form-control" v-model="sellFormData.phone" placeholder="PHONE" id="phone">
                  <span class="error-message">{{ sellFormData.errors.phone }}</span>
                </div>
                <div class="mb-3">
                  <textarea class="form-control" v-model="sellFormData.message" placeholder="TELL US ABOUT YOUR PROPERTY" id="message"></textarea>
                  <span class="error-message">{{ sellFormData.errors.message }}</span>
                </div>
                <div class="social-links switch-bg-dblue p-0 form-btn">
                  <button type="submit" class="btn mb-0 cta-link">SEND MESSAGE</button>
                </div>
                <div class="success-message" v-if="sellFormSuccessMessage">{{ sellFormSuccessMessage }}</div>
                <div class="error-message" v-if="sellFormErrorMessage">{{ sellFormErrorMessage }}</div>
              </form>
            </div>
          </div> -->
        </div>

      </div>
   </section>
    
   <section id="" class="my-5">
    <div class="container px-md-5">
     <div class="row justify-content-center switch-bg-blue bord-radius p-sm-5 p-3">
         <div class="col-12">
          <h1 class="switch-white text-start my-0">Head Office</h1>
          <p class="switch-white small-txt">First Realty Central (PTY) Ltd TA Chas Everitt International Properties Group is a Franchisor of the Chas Everitt International Property Group. Registered with the PPRA</p>
         </div>
         <div class="col-lg-2 pt-2">
            <div class="row g-0">
              <div class="col-lg-2 col-md-1 col-1"><img src="images/icons/telephone-icon.png" class="mw-100"/></div>
              <div class="col-10"><p class="mb-0 switch-white ps-3"><a href="tel:++27 11 100 8610" class="switch-white">+27 11 100 8610</a></p></div>
            </div>
        </div>
        <div class="col-lg-3 pt-2 ">
          <div class="row g-0">
            <div class="col-1"><img src="images/icons/email-icon.png" class="mw-100"/></div>
            <div class="col-11"><p class="mb-0 switch-white ps-3"><a href="mailto:luxuryenquiries@everitt.co.za" class="switch-white">luxuryenquiries@everitt.co.za</a></p></div>
          </div>
        </div>
        <div class="col-lg-5 pt-2 ">
          <div class="row g-0">
            <div class="col-1 pe-sm-4"><img src="images/icons/address-icon.png" class="mw-100"/></div>
            <div class="col-11"><p class="mb-0 switch-white ps-sm-0 ps-3">126 Kayburne Avenue, Randpark Ridge, 2169</p></div>
          </div>
        </div>
     </div>
    </div>
 </section>

   <!-- <section class="mt-5">
    <div class="container px-sm-5">
      <div class="row g-0">
        <div class="col-lg-5">
          <div class="switch-bg-lgrey p-5">
            <div class="input-group filter-search contact-search">
              <input type="text" class="form-control switch-bg-dgrey" placeholder="Find an agent" aria-label="Find an agent" aria-describedby="basic-addon2">
              <div class="input-group-append ">
                <span class="input-group-text switch-bg-lblue search-box-icon h-100" id="basic-addon2"><i class="bi bi-search switch-white"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 ms-auto">
          <div class="switch-bg-lgrey p-5">
            <div class="input-group filter-search contact-search">
              <input type="text" class="form-control switch-bg-dgrey" placeholder="Find an office" aria-label="Find an office" aria-describedby="basic-addon2">
              <div class="input-group-append ">
                <span class="input-group-text switch-bg-lblue search-box-icon h-100" id="basic-addon2"><i class="bi bi-search switch-white"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   </section> -->

    <Footer />
  </div>
</template>
<script>
import Menu from '@/components/home/Menu.vue'
import Footer from '@/components/home/Footer.vue'
import SeoLinks from '@/components/home/SeoLinks.vue'
import axios from 'axios'; // Import Axios if you haven't already

export default {
  components: {
    Menu, Footer, SeoLinks
  },
  data() {
    return {
      data: {
        name: "",
        email: "",
        phone: "",
        message: "",
        enquiry: "",
        receiveMarketing: false,
        acceptTerms: false,
        errors: {
          name: '',
          email: '',
          phone: '',
          message: '',
          enquiry: '',
        },
      },
      successMessage: '', // To display success message
      errorMessage: '',   // To display error message
    }
  },
  methods: {
    submitForm() {
      this.data.errors = {};
      this.successMessage = ''; // Clear any previous success message
      this.errorMessage = '';   // Clear any previous error message

      if (!this.data.name) {
        this.data.errors.name = "Name is required";
      }

      if (!this.data.email) {
        this.data.errors.email = "Email is required";
      }

      if (!this.data.phone) {
        this.data.errors.phone = "Phone is required";
      } else if (!/^\d+$/.test(this.data.phone)) {
        this.data.errors.phone = "Phone must contain only numeric characters";
      }

      if (!this.data.message) {
        this.data.errors.message = "Message is required";
      }

      if (!this.data.enquiry) {
        this.data.errors.enquiry = "Message is required";
      }

      if (Object.keys(this.data.errors).length === 0) {
        // Form is valid, send the email using Axios
        axios.post("https://stratitude.co.za/api/buyForm.php", {
          name: this.data.name,
          email: this.data.email,
          phone: this.data.phone,
          message: this.data.message,
          enquiry: this.data.enquiry,
          receiveMarketing: this.data.receiveMarketing
        })
        .then(response => {
          // Handle the success response
          this.successMessage = "Email sent successfully.";
          this.data.name = "";
          this.data.email = "";
          this.data.phone = "";
          this.data.message = "";
          this.data.enquiry = "";
          this.data.acceptTerms = false;
          this.data.receiveMarketing = false;
        })
        .catch(error => {
          // Handle the error response
          this.errorMessage = "Email sending failed.";
        });
      }
    },
  },
}


</script>
